import { FC, ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import './component.scss';
import { Cards, CardHeader, CardBody, CardFooter } from '@/components/card';
import { Button } from '@/components/button';
import { CONTACT_DETAILS } from '@/utils/constants';
import FormatValue from '@/utils/FormatValue';

interface ComponentProps {
  classNames?: string;
  children?: ReactNode;
}

export const ContactUsCard: FC<ComponentProps> = ({ classNames, children }) => {
  const router = useRouter(),
    classes = classNames
      ? `mrc-contact-us-card ${classNames}`
      : 'mrc-contact-us-card';

  return (
    <Cards className={classes}>
      <CardHeader className='card-header'>Contact us</CardHeader>
      <CardBody className='card-body'>
        <div className='flex items-center justify-center gap-1'>
          <span>Call us:</span>
          <a
            target='_blank'
            href={`tel:${CONTACT_DETAILS.phone}`}
            className='px-1 py-0 font-normal text-primary hover:underline'
          >
            {FormatValue.phoneNumber(CONTACT_DETAILS.phone)}
          </a>
        </div>

        <div className='flex flex-col items-center justify-center gap-2 text-center '>
          <span>Available hours:</span>
          <span>
            {FormatValue.formatDayRanges(CONTACT_DETAILS.days.open, [])} :{' '}
            {CONTACT_DETAILS.hours.start} to {CONTACT_DETAILS.hours.end}{' '}
            {CONTACT_DETAILS.timezone}
          </span>
        </div>
      </CardBody>
      <CardFooter>
        <Button
          variant='custom'
          className='card-footer'
          onClick={() => router.push(CONTACT_DETAILS.supportFormUrl)}
        >
          Fill out form
        </Button>
      </CardFooter>
    </Cards>
  );
};
