'use client';

import { ecoRebateData } from 'utils/specificationHelper';
import { FC, useEffect } from 'react';

export const RebaseEcoModal: FC<{ product: string; id?: string }> = ({
  product,
  id,
}) => {
  useEffect(() => {
    const customStyles = `
      .eco-m .eco-c {
        font-family: inherit;
      }
      .ecr-modal-content {
        border-radius: 24px;
      }
      body .eco-m .eco-c [ng-bind-html].ng-binding {
        line-height: 1.2;
        display: flex;
      }
    `;

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = customStyles;

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = `_ecr.ecorebates${id} = ${JSON.stringify(ecoRebateData[product])}`;

    document.body.appendChild(styleElement);
    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
      document.body.removeChild(styleElement);
    };
  }, [product, id]);

  return (
    <div id={`ecorebates${id}`} style={{ fontSize: 20, height: '100%' }} />
  );
};
