'use client';
import { useNewStore as useGlobalStore } from 'contexts/store-provider';
import { Button } from 'components/button';
import { Cards, CardBody, CardHeader } from 'components/card';
import Footer from 'components/footer';
import ExploreProducts from 'components/home/ExploreProducts';
import { IoStar, IoStarHalf } from 'react-icons/io5';
import { FiChevronRight } from 'react-icons/fi';
import { useEffect } from 'react';
import Link from 'next/link';
import { useAllBlogs } from 'stores/all-blog';
import { Container } from 'components/container';
import Image from 'next/image';
import type { Section } from 'types/homepage/HomepageApiResponse';

const Home = () => {
  const homePageData = useGlobalStore((state) => state.data);
  const { product_showcase, product_category_showcase } = homePageData;
  const title = homePageData?.sections.find(
    (data: Section) => data.__component === 'pages.section-title'
  );
  const section_hero = homePageData?.sections.find(
    (data: Section) => data.__component === 'pages.section-hero'
  );

  const { blogs, getBlogs } = useAllBlogs((state) => ({
    blogs: state.blogs,
    getBlogs: state.getBlogs,
  }));

  useEffect(() => {
    getBlogs(1, 1000);
  }, [getBlogs]);

  useEffect(() => {
    document.title = 'Homepage - MRCOOL';
  }, []);

  const blogLatest = blogs
    ?.sort(
      (a: any, b: any) =>
        new Date(b.attributes.date).getTime() -
        new Date(a.attributes.date).getTime()
    )
    .slice(0, 3)
    .map((data) => data);

  return (
    <main className='flex min-h-screen w-full flex-col overflow-hidden'>
      {/* section-1 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='flex flex-col gap-9 md:gap-16'>
            <div className='flex w-full flex-col items-center justify-center gap-4 text-center'>
              <h1 className='text-4xl font-bold md:text-7xl'>
                {title?.title || ''}
              </h1>
              <p className='text-lg md:text-xl'>{title?.subtitle || ''}</p>
            </div>
            <div className='grid grid-cols-2 gap-4 sm:gap-6 md:px-10 lg:grid-cols-4'>
              {product_showcase?.data?.map((data: any) => {
                const attr = data?.attributes;
                const thumb = attr?.thumbnail?.data?.attributes;
                return (
                  <Link
                    key={data.id}
                    href={`/product/${attr?.slug}`}
                    title={attr?.name}
                    className='group relative h-auto max-h-[220px] min-h-44 w-full rounded-xl hover:cursor-pointer'
                  >
                    <Cards className='and group relative h-full w-full rounded-xl rounded-xl bg-[#F7F8F9] hover:cursor-pointer'>
                      <CardBody className='relative flex h-full w-full flex-col items-center px-4 pb-6'>
                        {data.attributes.new_release && (
                          <span className='absolute left-3 top-3 z-[1] mr-auto rounded-full border border-blue-400 bg-blue-400 px-2 py-0.5 text-xs text-white'>
                            New
                          </span>
                        )}
                        {thumb && (
                          <img
                            className='group mb-2 mt-2 h-[150px] w-auto rounded-xl object-cover duration-300 group-hover:scale-110'
                            src={thumb?.url}
                            alt={thumb?.caption || 'Product thumbnail'}
                          />
                        )}
                        <p className='text-center text-base font-medium'>
                          {attr?.name}
                        </p>
                      </CardBody>
                    </Cards>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* section-2 */}
      <section className='py-5'>
        <div className='mx-auto w-full max-w-[90rem] px-5'>
          {section_hero ? (
            <div
              className='flex h-[26.25rem] w-full overflow-hidden rounded-[1.25rem] bg-cover bg-local bg-center object-cover p-4 sm:min-h-[680px] sm:p-6 md:h-full md:rounded-3xl'
              style={{
                backgroundImage: `url('${section_hero.image ? section_hero.image.data?.[0]?.attributes.url : ''}')`,
              }}
            >
              <div className='m-auto flex h-full w-full flex-col justify-center gap-6  p-6 text-center font-medium text-gray-900'>
                <div className='flex flex-col gap-5'>
                  <h5 className='text-base font-medium sm:text-lg'>
                    {section_hero.title}
                  </h5>
                  <h1 className='text-center text-4xl font-bold sm:text-5xl lg:text-8xl'>
                    {section_hero.description}
                  </h1>
                </div>
                <div className='flex flex-col md:mt-16'>
                  <Link href={`product/${section_hero.link}`}>
                    <Button
                      variant='link'
                      className='gap-x-1 text-base transition duration-150'
                    >
                      <span>{section_hero.link_label ?? ''}</span>
                      <FiChevronRight className='h-5 w-5 text-inherit' />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>

      {/* section-3 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          {product_category_showcase ? (
            <ExploreProducts ProductCategory={product_category_showcase.data} />
          ) : (
            ''
          )}
        </div>
      </section>

      {/* section-4 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='mx-auto mb-10 flex w-full flex-col gap-9 text-center sm:mb-16 lg:w-[640px]'>
            <h2 className='text-center text-4xl font-bold lg:text-5xl'>
              Join thousands enjoying their space in comfort.
            </h2>
            <div className='flex flex-col gap-2 text-center text-lg sm:text-xl'>
              <p>Over 1,560 verified customer reviews.</p>
              <div className='flex items-center justify-center gap-2'>
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStarHalf className='h-4 w-4 text-primary' />
              </div>
            </div>
          </div>

          <div className='grid w-full'>
            <img
              className='hidden h-full w-full rounded-3xl object-cover object-center duration-300 md:block'
              src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718901201/hp_act_bundle_01_3424c4b6ca.webp'
              alt='Image Description'
            />
            {/* Responsive */}
            <img
              className='h-full w-full rounded-3xl object-cover object-center duration-300 sm:max-h-none md:hidden'
              src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718901216/hp_act_bundle_01_mob_7fbb9b8993.webp'
              alt='Image Description'
            />
          </div>
        </div>
      </section>

      {/* section-5 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='grid w-full grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6'>
            <Cards className='full flex w-full flex-col gap-6 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Where can I buy?
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <h3 className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Explore nearby dealers and get the product you love.
                </h3>
                <Link href={{ pathname: '/locations' }} scroll={true}>
                  <Button color='primary' className='pill-button'>
                    Find a dealer
                  </Button>
                </Link>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Become a dealer
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <h3 className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Join our robust ecosystem and expand your opportunities.
                </h3>
                <div className='flex w-full items-center gap-x-3'>
                  <Link href={{ pathname: '/support/dealer-signup' }}>
                    <Button color='primary' className='pill-button'>
                      Learn more
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Rebate center
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <div className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Maximize savings. Enjoy additional benefits.
                </div>
                <Link href={{ pathname: '/rebates' }}>
                  <Button color='primary' className='pill-button'>
                    Visit Rebate Center
                  </Button>
                </Link>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Influencer program
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <div className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Connect with us through the MRCOOL® Influencer Program.
                </div>
                <div className='flex w-full items-center gap-x-3'>
                  <Link
                    href='/support/become-influencer'
                    as='/support/become-influencer#apply-influencer'
                  >
                    <Button color='primary' className='pill-button'>
                      Become an influencer
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Cards>
          </div>
        </div>
      </section>

      {/* section-6 */}
      <section className='py-5'>
        <div className='mx-auto w-full max-w-[90rem] px-5'>
          <div className='grid h-full w-full grid-cols-1 overflow-hidden rounded-3xl lg:min-h-[620px] lg:grid-cols-3'>
            <div
              className='h-[284px] w-full bg-cover bg-center sm:col-span-2 lg:h-full'
              style={{
                backgroundImage:
                  "url('https://res.cloudinary.com/dbkbadq4w/image/upload/v1718904871/hp_story_01_f1a35515ba.webp')",
              }}
            ></div>
            <div className='flex h-full w-full flex-col flex-col justify-between bg-primary px-5 py-7 text-white sm:px-9 sm:py-14'>
              <div className='text-2xl font-semibold md:text-xl lg:text-4xl'>
                At MRCOOL®, our drive for progress and innovation has led us to
                some pretty cool places
              </div>
              <Link
                href={{ pathname: 'our-story' }}
                className='mt-auto inline-flex items-center gap-2 text-base text-white hover:text-white lg:text-base'
              >
                <Button
                  variant='link'
                  className='mt-8 inline-flex items-center gap-x-1 text-base text-white hover:text-white lg:mt-auto lg:text-base'
                >
                  <span>Learn more about our story</span>
                  <FiChevronRight className='h-5 w-5 text-inherit' />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* section 7 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='mb-10 flex w-full flex-1 sm:mb-16'>
            <h2 className='text-3xl font-bold sm:text-5xl'>
              Stay informed and in touch.
            </h2>
          </div>

          <div className='grid w-full grid-cols-1 gap-6 md:grid-cols-3'>
            {blogLatest.map((data) => {
              return (
                <div
                  key={data?.id}
                  className='flex w-full flex-col gap-2 sm:gap-5'
                >
                  <Link href={{ pathname: `/blog/${data?.attributes?.slug}` }}>
                    <div className='h-[220px] lg:h-[280px]'>
                      {data?.attributes?.thumbnail?.data?.attributes?.url ? (
                        <Image
                          width={363}
                          height={280}
                          className='h-full w-full rounded-2xl object-cover'
                          src={
                            data?.attributes?.thumbnail?.data?.attributes?.url
                          }
                          alt={
                            data?.attributes?.thumbnail?.data?.attributes
                              ?.caption ?? ''
                          }
                        />
                      ) : (
                        <Container className='rounded-2xl' />
                      )}
                    </div>
                    <div className='mt-6 flex flex-col justify-between md:grow md:gap-3'>
                      <h4 className='my-3 text-lg font-bold leading-tight text-zinc-900 lg:text-2xl lg:leading-snug'>
                        {data?.attributes?.title}
                      </h4>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer slug={undefined} />
    </main>
  );
};

export default Home;
