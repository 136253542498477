import { FC, useCallback, memo } from 'react';
import Link from 'next/link';
import ModalComponent from '../modal/ModalComponent';
import { ProductCategories, DropdownProduct } from 'stores/types';
import { handleProductNavigation } from 'utils/navigationHelpers';

interface DesktopProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategories[];
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  productSelected: DropdownProduct[];
  isLoadingCategories: boolean;
  clientHeight?: string;
  router: any;
}

const ProductGrid = memo(
  ({
    products,
    onClose,
    router,
  }: {
    products: DropdownProduct[];
    onClose: () => void;
    router: any;
  }) => (
    <>
      {products.map((product, index) => (
        <Link
          key={product?.id}
          title={product?.attributes?.name}
          className='relative h-auto w-full rounded-xl bg-[#F7F8F9] p-4'
          href={`/product/${product?.attributes?.slug}`}
          onClick={(e) => {
            e.preventDefault();
            handleProductNavigation(
              product?.attributes?.slug,
              () => {},
              onClose,
              router
            );
          }}
        >
          <div className='flex flex-col items-center justify-start'>
            <img
              loading={index < 5 ? 'eager' : 'lazy'}
              className='h-[150px] w-full rounded-xl rounded-b-none object-cover object-center p-2'
              src={
                product?.attributes?.thumbnail?.data?.attributes?.url ||
                '/images/air-conditioner.png'
              }
              alt={product?.attributes?.name || 'Product image'}
            />
            <p className='text-center text-[15px] font-medium'>
              {product?.attributes?.name}
            </p>
          </div>
        </Link>
      ))}
    </>
  )
);
ProductGrid.displayName = 'ProductGrid';

const CategoryButton = memo(
  ({
    category,
    isSelected,
    onSelect,
    onPreload,
  }: {
    category: ProductCategories;
    isSelected: boolean;
    onSelect: (slug: string) => void;
    onPreload: (nextSlug: string) => void;
  }) => (
    <button
      key={category?.id}
      className={`all-products-categories-btn group mb-2 inline-block w-full rounded px-3 py-2 text-left duration-500 lg:hover:bg-gray-100 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
      onMouseEnter={() => {
        if (window.innerWidth >= 1024 && category?.attributes?.slug) {
          onSelect(category.attributes.slug);
          onPreload(category.attributes.slug);
        }
      }}
      onClick={() =>
        window.innerWidth < 1024 &&
        category?.attributes?.slug &&
        onSelect(category.attributes.slug)
      }
    >
      {category?.attributes?.name || ''}
    </button>
  )
);
CategoryButton.displayName = 'CategoryButton';

const LoadingSkeleton = memo(() => (
  <>
    {[...Array(10)].map((_, index) => (
      <div
        key={index}
        className='relative h-auto min-h-44 w-full rounded-xl bg-[#F7F8F9] p-4 transition-opacity duration-300'
      >
        <div className='flex flex-col items-center justify-start'>
          <div className='h-[150px] w-full rounded-xl rounded-b-none bg-gray-200/50 p-2' />
          <div className='mt-2 h-4 w-3/4 rounded bg-gray-200/50' />
        </div>
      </div>
    ))}
  </>
));
LoadingSkeleton.displayName = 'LoadingSkeleton';

const DesktopProductModal: FC<DesktopProductModalProps> = ({
  isOpen,
  onClose,
  categories,
  selectedCategory,
  setSelectedCategory,
  productSelected,
  isLoadingCategories,
  clientHeight = '0px',
  router,
}) => {
  const preloadNextCategory = useCallback(
    (nextSlug: string) => {
      const nextProducts = categories?.find(
        (item) => item?.attributes?.slug === nextSlug
      )?.attributes?.products?.data;

      nextProducts?.forEach((product) => {
        if (product?.attributes?.thumbnail?.data?.attributes?.url) {
          const img = new Image();
          img.src = product.attributes.thumbnail.data.attributes.url;
        }
      });
    },
    [categories]
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      close={onClose}
      className='mrcool-all-products-modal z-1000 min-h-fit overflow-visible rounded-b-none rounded-t-none'
      containerClass='h-auto'
      size={'full'}
      position='top'
      transition='top'
      style={{
        top: clientHeight,
      }}
    >
      <div className='mx-auto flex w-full max-w-[85rem] gap-4 px-5 py-6'>
        <div className='w-1/5 flex-none'>
          <h1 className='text-md mb-2 font-semibold'>Categories</h1>
          <div className='all-products-categories-list w-full'>
            {categories?.length > 0 &&
              categories?.map((cat) => (
                <CategoryButton
                  key={cat.id}
                  category={cat}
                  isSelected={cat?.attributes?.slug === selectedCategory}
                  onSelect={setSelectedCategory}
                  onPreload={(slug) => {
                    const nextIndex =
                      categories.findIndex((c) => c.attributes.slug === slug) +
                      1;
                    if (
                      nextIndex < categories.length &&
                      categories[nextIndex]?.attributes?.slug
                    ) {
                      preloadNextCategory(
                        categories[nextIndex].attributes.slug
                      );
                    }
                  }}
                />
              ))}
          </div>
        </div>

        <div className='flex-1'>
          <h1 className='text-md font-semibold'>Products</h1>
          <div className='relative mx-auto mt-2 grid h-full w-full grid-cols-5 gap-5 pb-8'>
            {isLoadingCategories ? (
              <LoadingSkeleton />
            ) : productSelected && productSelected?.length > 0 ? (
              <ProductGrid
                products={productSelected}
                onClose={onClose}
                router={router}
              />
            ) : null}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DesktopProductModal;
