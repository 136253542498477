import { FeatureFlags } from 'contexts/featureFlags';
import qs from 'qs';

export interface InitState {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: object;
  attributes: boolean;
}

export const fetchAPI = async <T,>(
  path: string,
  params: object = {},
  initState?: Partial<InitState>
): Promise<T> => {
  try {
    const hasParams = !!Object.keys(params).length,
      options = {
        method: initState?.method ?? 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(initState?.headers ?? {}),
        },
      };

    const requestUrl = new URL(path);
    if (hasParams) {
      requestUrl.search = qs.stringify(params);
    } else {
      const qstring = path.split('?')[1];
      if (qstring.length) requestUrl.search = qstring;
    }

    const response = await fetch(requestUrl, options),
      jsonData = await response.json();

    return !!initState?.attributes || initState === undefined
      ? (jsonData?.data?.attributes as T)
      : (jsonData as T);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default fetchAPI;
