import { FC, useState, useEffect, memo } from 'react';
import { MdClose, MdArrowBackIos } from 'react-icons/md';
import { useRouter } from 'next/navigation';
import ModalComponent from '../modal/ModalComponent';
import { ProductCategories, DropdownProduct } from 'stores/types';

interface MobileProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  categories: ProductCategories[];
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  clientHeight?: string;
  productSelected: DropdownProduct[];
  isLoadingCategories: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

const ProductList = memo(
  ({
    products,
    onProductClick,
  }: {
    products: DropdownProduct[];
    onProductClick: (slug: string) => void;
  }) => (
    <>
      {products.map((product) => (
        <button
          key={product.id}
          className='mrcool-mobile-modal-product-item mt-4 block w-full text-left'
          onClick={() => onProductClick(product?.attributes?.slug)}
        >
          {product?.attributes?.name}
        </button>
      ))}
    </>
  )
);

ProductList.displayName = 'ProductList';

const MobileLoadingSkeleton = memo(() => (
  <>
    {[...Array(5)].map((_, index) => (
      <div
        key={index}
        className='mt-4 h-8 w-full animate-pulse rounded bg-gray-200/50 transition-opacity duration-300'
      />
    ))}
  </>
));

MobileLoadingSkeleton.displayName = 'MobileLoadingSkeleton';

const MobileProductModal: FC<MobileProductModalProps> = ({
  isOpen,
  onClose,
  onBack,
  categories,
  selectedCategory,
  setSelectedCategory,
  clientHeight = '0px',
  productSelected = [] as DropdownProduct[],
  isLoadingCategories = false,
  setIsMobileMenuOpen,
}) => {
  const [showProducts, setShowProducts] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (isOpen && categories?.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]?.attributes?.slug || '');
    }
  }, [isOpen, categories, selectedCategory, setSelectedCategory]);

  useEffect(() => {
    if (!isOpen) {
      setShowProducts(false);
    }
  }, [isOpen]);

  const handleCategoryClick = (slug: string) => {
    setSelectedCategory(slug);
    setShowProducts(true);
  };

  const handleBackClick = () => {
    if (showProducts) {
      setShowProducts(false);
    } else {
      onBack();
    }
  };

  const handleProductClick = (slug: string) => {
    router.push(`/product/${slug}`);
    setTimeout(() => {
      setIsMobileMenuOpen(false);
      onClose();
    }, 0);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      close={onClose}
      className='z-1000 min-h-full rounded-b-none rounded-t-none'
      containerClass='h-full'
      size={'full'}
      position='top'
      transition='left'
      style={{
        top: clientHeight,
      }}
    >
      <div className='mrcool-mobile-modal-container px-4 py-2'>
        <div className='mrcool-mobile-modal-header flex w-full items-center justify-between lg:w-auto lg:flex-grow-0 lg:justify-start'>
          <button
            type='button'
            onClick={handleBackClick}
            className='mrcool-navbar-back-btn'
          >
            <MdArrowBackIos className='ml-1 size-7 pt-1 text-gray-600' />
          </button>
          <button
            type='button'
            onClick={onClose}
            className='mrcool-navbar-mobile-menu-btn mt-1.5'
          >
            <MdClose className='size-8 text-gray-600' />
          </button>
        </div>
        <div
          className={`mrcool-mobile-modal-content ml-10 mt-12 flex flex-col items-start ${!showProducts ? 'gap-4' : 'gap-2'} text-2xl text-gray-600`}
        >
          {!showProducts ? (
            <>
              <h2 className='mrcool-mobile-modal-title text-2xl font-semibold'>
                All Products
              </h2>
              {categories?.length > 0 &&
                categories?.map((cat) => (
                  <button
                    key={cat?.id}
                    className='mrcool-mobile-modal-category-btn no-hover-mobile inline-block w-full text-left'
                    onClick={() => {
                      if (cat?.attributes?.slug) {
                        handleCategoryClick(cat.attributes.slug);
                      }
                    }}
                  >
                    {cat?.attributes?.name || ''}
                  </button>
                ))}
            </>
          ) : (
            <>
              <h2 className='mrcool-mobile-modal-category-title text-2xl font-semibold'>
                {categories.find(
                  (cat) => cat.attributes.slug === selectedCategory
                )?.attributes?.name || 'Products'}
              </h2>
              <div className='mrcool-mobile-modal-products-grid -mt-2 w-full'>
                {isLoadingCategories ? (
                  <MobileLoadingSkeleton />
                ) : (
                  <ProductList
                    products={productSelected}
                    onProductClick={handleProductClick}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

export default MobileProductModal;
