import Link from 'next/link';
import { FC } from 'react';
import Button from '../common/Button';
import { BUTTON_TEXT } from 'utils/constants';

interface WhereToBuyBtnProps {
  isMobile: boolean;
  setMapOpen: (value: boolean) => void;
  size?: "small" | "default" | undefined
  title?: string | undefined;
  color?: string | undefined;
}

const WhereToBuyBtn: FC<WhereToBuyBtnProps> = ({ isMobile, setMapOpen, size, title, color }) => {
  if (isMobile) {
    return (
        <Link href='/locations'>
            <Button
              className='pill-button'
              title={title ?? BUTTON_TEXT.shopNow.btnText}>
              {title ?? BUTTON_TEXT.shopNow.btnText}
            </Button>
        </ Link>
    )
  }
    return (
    <Button
      className='pill-button'
      title={title ?? BUTTON_TEXT.shopNow.btnText}
      onClick={() => setMapOpen(true)}>
      {title ?? BUTTON_TEXT.shopNow.btnText}
    </Button>
  );
};

export default WhereToBuyBtn;
