'use client';

import { useEffect, useState } from 'react';
import { FOOTER_LINKS, FOOTER_TEXT } from 'utils/constants';
import Link from 'next/link';
import React, { FC } from 'react';
import { RebaseEcoModal } from '../rebates';
import { SocialMediaLinks } from '../cards/social-media/links';
import { useDeviceDetect } from 'utils/useDeviceDetect';
import { useStore } from 'zustand';
import { useMapStore } from 'stores/map/mapStore';
import WhereToBuyBtn from '../button/where-to-buy-btn';
import { ecoRebateData } from 'utils/specificationHelper';
import { ContactUsCard } from '../cards/contact-us';
import { LandingCardBase } from '../landing/LandingCardBase';
import Text from '../common/Text';
import { CardBody, CardFooter, CardHeader, Cards } from '../card';
import { Button } from '../button';
import { useRouter } from 'next/navigation';
import './component.scss';

const splitLinksIntoColumns = (
  linksArray: { href: string; text: string; target?: string }[]
) => [linksArray.slice(0, 7), linksArray.slice(7)];

export const Footer: FC<{
  slug: string | undefined;
  color?: string;
}> = ({ slug, color }) => {
  const router = useRouter();
  const isMobile = useDeviceDetect();
  const [showStickyFooter, setShowStickyFooter] = useState(false);
  const { setModalMapOpen } = useStore(useMapStore, (state) => ({
    setModalMapOpen: state.setModalMapOpen,
  }));
  const columns = splitLinksIntoColumns(FOOTER_LINKS);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const header = document.querySelector('header');
          const footer = document.querySelector('footer');

          if (!header || !footer) return;

          const headerRect = header.getBoundingClientRect();
          const isHeaderVisible = headerRect.bottom > 0;
          const isNearFooter =
            window.innerHeight + window.scrollY >
            document.documentElement.scrollHeight - 300;

          setShowStickyFooter(!isHeaderVisible && !isNearFooter);
          lastScrollY = window.scrollY;
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const ecoLinkStyles = `
      .eco-m .eco-c a {
        cursor: pointer;
        color: ${color || '#1c8eff'}
      }
    `;
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = ecoLinkStyles;

    document.body.appendChild(styleElement);

    return () => {
      document.body.removeChild(styleElement);
    };
  }, [color]);

  return (
    <footer className='relative overflow-hidden bg-white'>
      <div className='mx-auto w-full max-w-[90rem] px-5 pt-16 sm:px-6 lg:px-[120px]'>
        <div className='flex flex-col gap-y-16 sm:gap-y-20'>
          <div className='grid grid-cols-2 gap-4 sm:grid-cols-4 sm:gap-9'>
            <ContactUsCard classNames='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1' />
            <Cards className='mrc-contact-us-card col-span-2 border bg-white p-6 text-center sm:col-span-2 md:col-span-2 lg:col-span-1'>
              <CardHeader className='card-header'>Become a dealer</CardHeader>
              <CardBody className='card-body'>
                <Text size='paragraph'>
                  Expand opportunities. Join our robust ecosystem.
                </Text>
              </CardBody>
              <CardFooter>
                <Button
                  variant='custom'
                  className='card-footer'
                  onClick={() => router.push('/support/dealer-signup')}
                >
                  Learn more
                </Button>
              </CardFooter>
            </Cards>

            {/* <div className='sm:hidden lg:inline-block' /> */}
            {columns.map((column, colIndex) => (
              <div key={colIndex} className='col-span-2 flex sm:col-span-1'>
                <div className='flex flex-col gap-y-5'>
                  {column.map((link, i) => (
                    <div key={i}>
                      <Link
                        href={{ pathname: link.href }}
                        scroll={true}
                        target={link.target || '_self'}
                        className='inline-flex gap-x-2 text-[15px] text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                      >
                        {link.text}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-y-6'>
            <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
              <Link
                href={{ pathname: '/' }}
                scroll={true}
                className='flex-none'
              >
                <img
                  src='/images/mrcool-logo.svg'
                  alt='logo'
                  className='w-full max-w-40'
                />
              </Link>

              <div className='mt-9 flex items-center gap-2 sm:mt-0 sm:justify-center'>
                <div className='grid grid-cols-6 gap-3'>
                  <SocialMediaLinks classNames='social-footer mrc-social-links' />
                </div>
              </div>
            </div>
            <div className='pb-12  pt-6'>
              <div className='flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between'>
                <div className='text-sm text-gray-600 dark:text-gray-400'>
                  {FOOTER_TEXT.copyright}
                </div>

                <div className='mb-5 flex flex-col gap-4 text-sm sm:gap-8 lg:mb-0 lg:flex-row'>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/privacy-policy' }}
                  >
                    {FOOTER_TEXT.privacyPolicy}
                  </Link>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/terms-and-conditions' }}
                  >
                    {FOOTER_TEXT.termsAndConditions}
                  </Link>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/warranty-disclaimer' }}
                  >
                    Warranty Disclaimer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {slug !== undefined && (
        <div
          className={`mrc-sticky-footer fixed bottom-0 left-0 z-[999] w-full transform bg-white shadow-lg transition-all duration-200 ease-in-out ${
            showStickyFooter ? 'translate-y-0' : 'translate-y-full'
          }`}
          style={{
            WebkitBackfaceVisibility: 'hidden',
            WebkitPerspective: 1000,
            WebkitTransform: 'translate3d(0,0,0)',
            backfaceVisibility: 'hidden',
            perspective: 1000,
            transform: 'translate3d(0,0,0)',
            visibility: showStickyFooter ? 'visible' : 'hidden',
          }}
        >
          <div className='mrc-sticky-footer__container mx-auto flex h-16 w-full flex-row items-center justify-center gap-4 px-3 py-3 sm:py-5 md:h-20 md:max-w-md md:px-4'>
            {ecoRebateData[slug || ''] && (
              <div className='mrc-sticky-footer__rebate flex flex-1 justify-center text-center [&>button]:flex [&>button]:h-8 [&>button]:max-h-8 [&>button]:w-full [&>button]:items-center [&>button]:justify-center [&>button]:px-4 [&>button]:text-sm sm:[&>button]:h-10 sm:[&>button]:text-base [&>div]:flex [&>div]:h-8 [&>div]:items-center sm:[&>div]:h-10'>
                <RebaseEcoModal product={slug || ''} id='foter01' />
              </div>
            )}
            <div
              className={`mrc-sticky-footer__where-to-buy flex ${!ecoRebateData[slug || ''] ? 'w-full max-w-[200px]' : 'flex-1'} justify-center text-center [&>button]:flex [&>button]:h-8 [&>button]:max-h-8 [&>button]:w-full [&>button]:items-center [&>button]:justify-center [&>button]:pl-5 [&>button]:text-sm sm:[&>button]:h-10 sm:[&>button]:text-base`}
            >
              <WhereToBuyBtn
                isMobile={isMobile}
                setMapOpen={setModalMapOpen}
                title='Where to Buy'
              />
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
