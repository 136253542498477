import { createStore } from 'zustand/vanilla';
import {
  GeoLocation,
  MapData,
  MapDataAttributes,
  SelectedPlace,
} from 'components/map/type';
import {
  BASE_URL,
  MAP_INITIAL_STATE,
  MAP_RADIAL_OPTIONS,
  MAP_RADIAL_SLUG,
  MAP_SLUG,
  MAP_MAX_SEARCH_RADIUS,
  DEFAULT_MAP_RADIUS,
  DEFAULT_MAP_ZOOM,
  GOOGLE_MAPS_API,
  MAP_PREFIX,
  API_KEY,
} from 'utils/constants';
import { RadialOptionTypes } from 'types';

interface ActiveMarker {
  position: google.maps.LatLngLiteral;
  label: string;
  info: MapDataAttributes;
  isDispatch: boolean;
}

interface RadialData {
  inside: MapData[];
  outside: MapData[];
}

interface MapStoreState {
  modalMapOpen: boolean;
  activeMarker: ActiveMarker | null;
  locations: MapData[] | null;
  zoom: number;
  selectedKey: string | null;
  address: {
    selected: SelectedPlace;
  };
  hasLoaded: {
    initialLoad: boolean;
    partners: boolean;
    dispatch: boolean;
  };
  data: {
    dispatch: MapData[];
    partner: MapData[];
    radialData: RadialData;
  };
  distanceOptions: {
    selected: number | null;
  };
  mapCenter: {
    geometry: GeoLocation;
  };
  yourLocation: GeoLocation;
  currentPage: {
    page: number;
  };
}

export type MapStoreActions = {
  setModalMapOpen: (value: boolean) => void;
  setLocations: (locations: MapData[] | null) => void;
  setSelectedKey: (selectedKey: string | null) => void;
  setActiveMarker: (data: ActiveMarker | null) => void;
  setRadiusData: (radialData: RadialData) => void;
  setDefaultDistance: (option: number) => void;
  getSelectedDistance: (id?: number) => RadialOptionTypes | null;
  setSelectedDistance: (item: RadialOptionTypes | null) => void;
  getDispatchData: () => Promise<MapData[]>;
  getPartnerData: (
    start: number,
    limit: number,
    allData: MapData[]
  ) => Promise<MapData[]>;
  setMapCenter: (geometry: GeoLocation) => void;
  setCurrentPage: (page: number) => void;
  setYourLocation: (lat: number, lng: number) => void;
  hasSetLocation: () => boolean;
  setZoom: (zoom: number) => void;
  setSelected: (selected: SelectedPlace) => void; // Added as a standalone function
  findClosestStore: (updateStateStore: boolean) => RadialData;
  getCurrentPosition: () => void;
  updateMapCenter: (dispatch?: boolean) => void;
};

export type MapStore = MapStoreState & MapStoreActions;

export const createMapStore = (initState: MapStoreState) => {
  return createStore<MapStore>((set, get) => ({
    ...initState,
    setModalMapOpen: (modalMapOpen: boolean) => {
      set({ modalMapOpen });
    },
    // Set the locations for all the markers
    setLocations: (locations: MapData[] | null) => {
      set({ locations });
    },
    setSelectedKey: (selectedKey: string | null) => {
      set({ selectedKey });
    },
    setActiveMarker(data: ActiveMarker | null) {
      set(() => ({ activeMarker: data }));
    },
    setSelected: (selected) => {
      get().setActiveMarker(null);
      get().setSelectedKey(null);
      set((state) => ({ address: { ...state.address, selected } }));
      set(() => ({ mapCenter: { geometry: selected.geometry } }));
      set(() => ({ currentPage: { page: 1 } }));
      get().updateMapCenter(true);
    },
    setZoom: (zoom: number) => {
      set({ zoom });
    },
    setRadiusData: (radialData) => {
      const data = get().data;
      data.radialData = radialData;
      set({ data });
    },
    setDefaultDistance: (distanceOption) => {
      set(() => ({
        distanceOptions: {
          selected: distanceOption,
        },
      }));
    },
    getSelectedDistance: () => {
      const selectedDistanceIdx = get().distanceOptions.selected;

      return selectedDistanceIdx !== null && selectedDistanceIdx >= 0
        ? MAP_RADIAL_OPTIONS[selectedDistanceIdx]
        : null;
    },
    setSelectedDistance: (radialOptions) => {
      const itemId =
        radialOptions &&
        MAP_RADIAL_OPTIONS.findIndex(
          (MAP_RADIAL_OPTIONS) =>
            MAP_RADIAL_OPTIONS.value === radialOptions.value
        );

      if (radialOptions) {
        const mapCenter = get().mapCenter;
        const yourLocation = get().yourLocation;

        set({
          distanceOptions: {
            selected: itemId,
          },
        });

        set(() => ({
          mapCenter: {
            geometry: mapCenter.geometry.lat
              ? mapCenter.geometry
              : yourLocation,
          },
        }));
        get().setZoom(radialOptions.zoom);
      } else {
        console.log('no radialOptions');
        set({ distanceOptions: { selected: null } });
        get().setZoom(DEFAULT_MAP_ZOOM);
      }

      get().findClosestStore(true);
    },
    getDispatchData: async (): Promise<MapData[]> => {
      try {
        set(() => ({ hasLoaded: { ...get().hasLoaded, dispatch: false } }));

        const { lat: latitude, lng: longitude } = get().mapCenter.geometry;
        const distance =
          get().getSelectedDistance()?.value || MAP_MAX_SEARCH_RADIUS;
        const url =
          BASE_URL +
          MAP_RADIAL_SLUG +
          `?latitude=` +
          latitude +
          `&longitude=` +
          longitude +
          `&distance=` +
          distance +
          `&unit=mi`;

        const response = await fetch(url);
        const result = await response.json();
        const fetchedData = result.response.account_organizations;
        const processedData = fetchedData
          ?.filter(
            (dealer: any) =>
              dealer.organization_address?.longitude &&
              dealer.organization_address?.latitude
          )
          ?.map((dealer: any, i: any) => ({
            key: `dispatch-${i}`,
            id: dealer.organization_id,
            dispatch: true,
            isOpenWindow: false,
            attributes: {
              longtitude: dealer.organization_address?.longitude,
              latitude: dealer.organization_address?.latitude,
              title: dealer.organization_name,
              address: dealer.organization_address?.street_1,
              postal_code: dealer.organization_address?.postal_code,
              city: dealer.organization_address?.city,
              state: dealer.organization_address?.state,
              description: '',
              phone: dealer.organization_phone_number,
              website: '',
              email: dealer.organization_email,
              organization: {
                external_id: dealer?.external_ids?.[0],
                name: dealer.organization_name,
              },
              job: {
                title: '',
                description: '',
                external_id: `ext${dealer?.external_ids?.[0]}`,
                address: {
                  street_1: '',
                  street_2: '',
                  postal_code: '',
                  city: null,
                  state: null,
                  country: null,
                },
                contacts: [],
              },
            },
          }));

        const { data } = get();
        data.dispatch = processedData;
        set({ data });
        set(() => ({ hasLoaded: { ...get().hasLoaded, dispatch: true } }));
        return processedData;
      } catch (error) {
        console.error('Error fetching dispatch data:', error);
        return [];
      }
    },
    getPartnerData: async (
      start: number,
      limit: number,
      allData: MapData[]
    ): Promise<MapData[]> => {
      try {
        const url =
          BASE_URL +
          MAP_SLUG +
          `?pagination[start]=` +
          start +
          `&pagination[limit]=` +
          limit +
          `&populate[store_types][fields][0]=name`;

        const response = await fetch(url);
        const result = await response.json();
        const { data: fetchedData } = result;
        const processedData = fetchedData
          .filter(
            (item: any) =>
              item.attributes?.latitude && item.attributes?.longtitude
          )
          .map((item: any) => ({
            ...item,
            id: item.id,
            dispatch: item.dispatch,
            isOpenWindow: false,
            attributes: {
              ...item.attributes,
              latitude: Number(item.attributes.latitude),
              longtitude: Number(item.attributes.longtitude),
            },
          }));

        allData.push(...processedData);

        if (result.data.length === 0) {
          const data = get().data;
          set(() => ({ hasLoaded: { ...get().hasLoaded, partners: true } }));

          data.partner = allData;
          set({ data });

          return allData;
        } else {
          return get().getPartnerData(start + limit, limit, allData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        return allData;
      }
    },
    findClosestStore: (updateStateStore: boolean): RadialData => {
      const { dispatch, partner } = get().data,
        combinedData = [...(dispatch ?? []), ...partner],
        radius = get().getSelectedDistance()?.value || DEFAULT_MAP_RADIUS,
        radialData: RadialData = {
          inside: [],
          outside: [],
        };

      get().setLocations(combinedData);

      combinedData
        ?.filter(
          (store) => store.attributes?.latitude || store.attributes?.longtitude
        )
        ?.forEach((store) => {
          const coords: GeoLocation = {
            lat: store.attributes.latitude,
            lng: store.attributes.longtitude,
          };
          const storeLocation = new google.maps.LatLng(coords);
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              get().mapCenter.geometry,
              storeLocation
            );
          if (distance <= radius * 1609.34) {
            radialData.inside.push(store);
          } else {
            radialData.outside.push(store);
          }
        });

      if (updateStateStore) {
        get().setRadiusData(radialData);
      }

      return radialData;
    },
    setMapCenter: (geometry) => {
      set({ mapCenter: { geometry } });
    },
    setCurrentPage: (page) => {
      set({ currentPage: { page } });
    },
    setYourLocation: (lat, lng) => {
      const geoLocation: GeoLocation = {
        lat: lat,
        lng: lng,
      };

      set({ yourLocation: geoLocation });
      set({ mapCenter: { geometry: geoLocation } });
    },
    hasSetLocation: () => {
      const coords = get().yourLocation;
      return !!(coords.lat && coords.lng);
    },
    getCurrentPosition: () => {
      const success = async (position: GeolocationPosition) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        const url = `${GOOGLE_MAPS_API}${MAP_PREFIX}?latlng=${lat},${lng}&key=${API_KEY}`;

        try {
          const response = await fetch(url);
          const data = await response.json();

          if (data.status === 'OK') {
            const result = data.results[0];

            for (const component of result.address_components) {
              if (
                component.short_name === 'US' ||
                component.short_name === 'CA'
              ) {
                get().setYourLocation(lat, lng);
                get().updateMapCenter(true);
              }
            }
          }
        } catch (err) {}
      };

      navigator.geolocation.getCurrentPosition(success);
    },
    updateMapCenter: (loadDispatch = true) => {
      if (loadDispatch) {
        get().getDispatchData();
      }
      const radialIdx = MAP_RADIAL_OPTIONS.findIndex(
        (MAP_RADIAL_OPTIONS) =>
          MAP_RADIAL_OPTIONS.value === MAP_MAX_SEARCH_RADIUS
      );
      get().setDefaultDistance(radialIdx);
      get().setZoom(MAP_RADIAL_OPTIONS[radialIdx].zoom);
    },
  }));
};

export const useMapStore = createMapStore(MAP_INITIAL_STATE);
