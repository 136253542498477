import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export const handleProductNavigation = (
  slug: string,
  setIsMobileMenuOpen: (isOpen: boolean) => void,
  onClose: () => void,
  router: AppRouterInstance
) => {
  router.push(`/product/${slug}`);
  setTimeout(() => {
    setIsMobileMenuOpen(false);
    onClose();
  }, 0);
};
